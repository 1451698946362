<style lang="scss">
#frontend {
  .header-image-box {
    width: 100%;
    margin-top: 30px;



    .inner-image-box {
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 700px;
      background-position: center top;
      position: relative;

      //border:1px solid red;
      @media (min-width: 2000px) {
        height: 800px;
      }

      @media (min-width: 3000px) {
        height: 900px;
      }

      @media (max-width: 1600px) {
        height: 600px;
      }

      @media (max-width: 1200px) {
        height: 500px;
      }

      @media (max-width: $width-lg) {
        background-position: center center;
      }

      @media (max-width: $width-md) {
        height: 350px;
      }

      .container-video {
        //border:1px solid blue;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        //height: 700px;
        overflow: hidden;
        display: none;

        .background-video {
          position: absolute;
          top: 0;
          left: 0;

          /*width: 100%    !important;
          height: auto   !important;
          @media(max-width:$width-md) {
            height:350px !important;
            width:auto !important;
          }*/
          @media (min-width: 4000px) {
            display: none;
          }

          @media (min-aspect-ratio: 16/9) {
            width: 100%;
            height: auto;
          }

          /*@media (max-aspect-ratio: 16/9) {
            width:auto;
            height: 100%;
          }*/
          @media (max-width: 767px) {
            display: none;
          }
        }
      }

      .raster-overlay {
        //border:1px solid red;
        display: none;

        @media (max-width: $width-lg) {
          display: none;
        }

        [class*='col-'] {
          //border:1px solid blue;
        }

        .col-height {
          height: 300px;

          @media (max-width: $width-xl) {
            height: 200px;
          }
        }

        .text-typewriter-outer {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          //border:1px solid red;
          line-height: 1;
          overflow: hidden;
          width: 100%;
        }

        .txt-typewriter {
          color: #fff;
          display: block;
          text-align: center;
          position: relative;

          //font-family:"Helvetica Neue LT W05 65 Medium";
          margin: 0 auto;
          letter-spacing: 2px;
          text-align: center;
          font-size: 600%;
          white-space: nowrap;
          overflow: hidden;
          opacity: 1;
          font-family: 'American Typewriter ITC W04 Md';

          .caret {
            background-color: #fff;
            animation: blink-caret 0.95s infinite;
            width: 3px;
          }

          @keyframes blink-caret {

            from,
            to {
              background-color: transparent;
            }

            50% {
              background-color: #fff;
            }
          }

          @media (max-width: 1600px) {
            font-size: 400%;
          }

          @media (max-width: $width-xl) {
            //border:1px solid red;
            font-size: 300%;
          }
        }

        .r-border-bottom {
          border-bottom: 1px solid #fff;
        }

        .r-border-right {
          border-right: 1px solid #fff;
        }
      }


      .title-box {
        position: relative;
        top: 50%;
        transform: translateY(-30%);

        @media (max-width: $width-lg) {
          display: block;
        }

        h1 {
          text-align: center;
          font-size: 600%;
          font-family: 'American Typewriter ITC W04 Md';
          color: #fff;

          @media (max-width: 1600px) {
            font-size: 400%;
          }

          @media (max-width: $width-xl) {
            //border:1px solid red;
            font-size: 300%;
          }

          @media (max-width: $width-md) {
            //border:1px solid red;
            font-size: 200%;
          }

          .caret {
            background-color: #fff;
            animation: blink-caret 0.95s infinite;
            width: 3px;
          }

          @keyframes blink-caret {

            from,
            to {
              background-color: transparent;
            }

            50% {
              background-color: #fff;
            }
          }

          /*span {
            font-family:"Helvetica Neue LT W05 96 Blk It";
            display:block;
             color: #fff;
            -webkit-text-fill-color: transparent; 
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            text-transform: uppercase;
            font-style: italic;
            font-weight:700;
            font-size:230%;
            @media(max-width:$width-lg) {
              font-size:150%;
            }
            @media(max-width:$width-md) {
              font-size:120%;
            }
            @media(max-width:$width-md) {
              font-size:100%;
            }
          }*/
        }
      }
    }
  }

  .header-special-start {
    width: 100%;
    margin-top: 30px;
    height: 700px;
    //border: 1px solid red;
    //display: none;
    position: relative;
    overflow: hidden;
    margin-bottom: 100px;
    display: none;



    @media(max-width:768px) {
      height: 600px;
      margin-bottom: 50px;
    }

    @media(max-width:400px) {
      height: 400px;
      margin-bottom: 20px;
      //border: 1px solid red;
    }

    .banner-box {
      height: 700px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;


      @media(max-width:768px) {
        height: 500px;

      }

      @media(max-width:400px) {
        height: 400px;

      }

      iframe {

        width: 100%;
        border: 0;
        height: 90%;
      }
    }

    .txt-box {
      text-align: center;
      font-size: 200%;
      position: relative;
      z-index: 3;
      //border: 1px solid blue;
      position: absolute;
      bottom: 5%;
      width: 100%;
      line-height: 1.3;
      font-family: 'American Typewriter ITC W04 Md';

      @media(max-width:768px) {
        font-size: 150%;

      }

      @media(max-width:400px) {
        bottom: 0;


      }

      .breaker {
        display: block;
        height: 1px;
      }


    }

    #bg-stars {

      background-position: center center;
      background-repeat: no-repeat;
      background-size: 95%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: scale(0.8);
      animation: bg-stars 13.2s infinite;
      z-index: -1;

      @media(max-width:1200px) {
        background-size: 140%;
      }

      @media(max-width:768px) {
        background-size: cover;
      }
    }

    @keyframes bg-stars {
      0% {
        transform: scale(0.8);
        opacity: .3;
        /*box-shadow: 0 0 0 0 rgba(107, 64, 143, 0.8);*/
      }

      /*
  15% {
    transform: scale(0.8);
    opacity: .3;
 
  }*/

      50% {
        transform: scale(1);
        opacity: .9;
        /*box-shadow: 0 0 0 10px rgba(107, 64, 143, 0);*/
      }

      100% {
        transform: scale(0.8);
        opacity: .3;
        /*box-shadow: 0 0 0 0 rgba(107, 64, 143, 0);*/
      }
    }
  }
}
</style>

<template>
  <div class="header-image-box">
    <div class="inner-image-box"
      :style="{ 'background-image': `url(${require('@/assets/img/header-img-styria.jpg')})` }">
      <!--<div class="container-video">
        <video autoplay muted loop class="background-video">
          <source src="@/assets/video/styria.mp4" type="video/mp4" />
        </video>
      </div>-->

      <div class="raster-overlay">
        <div class="container-fluid">
          <div class="row justify-content-center r-border-bottom">
            <div class="col-lg-2 col-height r-border-right"></div>
            <div class="col-lg-10 col-height"></div>
            <!--<div class="col-lg-1 col-height"></div>-->
          </div>
          <div class="row justify-content-center r-border-bottom">
            <div class="col-lg-4 col-height r-border-right"></div>
            <div class="col-lg-6 col-height r-border-right"></div>
            <div class="col-lg-2 col-height"></div>
          </div>
        </div>
      </div>
      <div class="title-box">
        <h1>
          <TypeWriter :prefix="$t('Home.wir')"
            :text="[($t('Home.informieren')), ($t('Home.unterhalten')), ($t('Home.vernetzen'))]" caret :delay="2500" />
        </h1>
      </div>
    </div>
  </div>
  <div class="header-special-start">
    <div class="banner-box">

    </div>
    <div class="txt-box" v-html="$t('General.xmas-txt')"></div>
    <div id="bg-stars"
      :style="{ 'background-image': `url(${require('@/assets/img/startseite/special-bg-stars.png')})` }">

    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
// @ts-ignore
import TypeWriter from './TypeWriter.vue';

export default defineComponent({
  components: { TypeWriter },
  setup() {
    const router = useRouter();

    return {};
  },
});
</script>
