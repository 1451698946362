<style lang="scss">
#frontend {
  .topsite-navigation {
    padding-top: 160px;

    @media (max-width: $width-lg) {
      display: none;
    }

    @media (max-width: $width-lg) {
      margin-top: 40px;
    }

    .navbar-nav {
      text-align: center;
      display: block;

      .nav-item {
        display: inline-block;
        margin: 0 40px;

        @media (max-width: 1400px) {
          margin: 0 30px;
        }

        @media (max-width: 1200px) {
          margin: 0 15px;
        }

        .nav-link {
          //font-family:"Helvetica Neue LT W05_75 Bold";
          padding: 0.5rem 0.3rem;
          display: inline-block;
          color: #000;
          //border: 1px solid red;
          //text-transform: uppercase;
          letter-spacing: 2px;
          position: relative;

          @media (max-width: 1200px) {
            font-size: 90%;
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 0%;
            height: 3px;
            background: #fff;
            transition: all 300ms ease;
          }

          &:after {
            border-top: 6px solid #000;
            border-right: 6px solid transparent;
            border-bottom: 0;
            border-left: 6px solid transparent;
            position: relative;
            top: 0px;
            display: none;
          }

          &:hover {
            &:before {
              width: 100%;
              background: #000;
            }
          }
        }

        .dropdown-toggle {
          &:after {
            //display:none;
          }
        }

        .dropdown-toggle.show {

          //font-family:"Helvetica Neue LT W05_75 Bold";
          &:before {
            width: 100%;
          }
        }

        .dropdown-menu {
          border-radius: 0;
          border: 0;
          border-bottom: 5px solid $sand;
          padding: 1rem;
          padding-top: 1.8rem;
          left: -20px !important;

          .dropdown-header {
            padding: 0.5rem;
            text-transform: uppercase;
            color: #212529;
            font-family: 'Helvetica Neue LT W05 65 Medium';
            font-size: 100%;
            letter-spacing: 1px;
          }

          .dropdown-item {
            padding: 0.5rem;
            letter-spacing: 1px;
            font-size: 90%;

            &:focus,
            &:active {
              background: transparent;
              color: #000;
            }

            &:hover {
              background: $grey;
            }
          }

          .router-link-active {
            //border:1px solid red;
            background: $sand;

            &:hover {
              opacity: 1;
              background: $sand;
            }
          }

          .dropdown-item-sub {
            padding-left: 25px;
            position: relative;

            &:before {
              content: '-';
              display: inline-block;
              position: absolute;
              left: 10px;
              top: 50%;
              transform: translateY(-55%);
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="topsite-navigation">
    <div class="container">
      <div class="row">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-brands" data-bs-toggle="dropdown">
              {{ $t('General.brands') }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown-brands">
              <router-link :to="{ name: 'media' }" class="dropdown-item">{{ $t('General.media') }}</router-link>
              <router-link :to="{ name: 'marketplaces-uebersicht' }" class="dropdown-item">
                {{ $t('General.marketplaces') }} </router-link>

              <!--<router-link :to="{ name: 'marketplaces-ueber-uns' }" class="dropdown-item dropdown-item-sub">{{$t('General.ueber-uns')}}</router-link>
              <router-link :to="{ name: 'marketplaces-marken' }" class="dropdown-item dropdown-item-sub">{{$t('General.marken')}}</router-link>-->
              <!--<router-link :to="{ name: 'marketplaces-technology' }" class="dropdown-item dropdown-item-sub">{{$t('General.technology')}}</router-link>-->
              <!--<router-link :to="{ name: 'marketplaces-startup-ventures' }" class="dropdown-item dropdown-item-sub" v-html="$t('General.startup-ventures')"></router-link>
              <router-link :to="{ name: 'marketplaces-karriere' }" class="dropdown-item dropdown-item-sub">{{$t('General.karriere')}}</router-link>
              <router-link :to="{ name: 'marketplacesnews' }" class="dropdown-item dropdown-item-sub">{{$t('General.news')}}</router-link>-->
              <router-link :to="{ name: 'service-partner' }" class="dropdown-item">{{ $t('General.service-partner') }}
              </router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-ueber-uns" data-bs-toggle="dropdown">
              {{ $t('General.ueber-uns') }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown-ueber-uns">
              <router-link :to="{ name: 'was-uns-ausmacht' }" class="dropdown-item">{{ $t('General.was-uns-ausmacht') }}
              </router-link>
              <router-link :to="{ name: 'wo-wir-sind' }" class="dropdown-item">{{ $t('General.wo-wir-sind') }}
              </router-link>
              <router-link :to="{ name: 'management' }" class="dropdown-item">{{ $t('General.management') }}
              </router-link>
              <router-link :to="{ name: 'geschichte' }" class="dropdown-item">{{ $t('General.geschichte') }}
              </router-link>
              <router-link :to="{ name: 'enviromental-social-governance' }" class="dropdown-item">
                {{ $t('General.enviromental-social-governance') }}</router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-karriere" data-bs-toggle="dropdown">
              {{ $t('General.karriere') }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown-karriere">
              <router-link :to="{ name: 'as-good-as-our-people' }" class="dropdown-item">
                {{ $t('General.as-good-as-our-people') }}</router-link>
              <router-link :to="{ name: 'berufs-bilder' }" class="dropdown-item">{{ $t('General.berufsbilder') }}
              </router-link>
              <router-link :to="{ name: 'offene-positionen' }" class="dropdown-item">{{ $t('General.offene-positionen')
              }}
              </router-link>
              <!--<a href="https://karriere.styria.com" target="_blank" class="dropdown-item">{{$t('General.offene-positionen')}}</a>-->
              <router-link :to="{ name: 'talentepool' }" class="dropdown-item">{{ $t('General.talentepool') }}
              </router-link>
              <router-link :to="{ name: 'styrianerIn-werden' }" class="dropdown-item"
                v-html="$t('General.styrianner-werden')"></router-link>
              <router-link :to="{ name: 'karriere-weiterbildung' }" class="dropdown-item"
                v-html="$t('General.karriere-weiterbildung')"></router-link>
              <router-link :to="{ name: 'lehre-praktika' }" class="dropdown-item" v-html="$t('General.lehre-praktika')">
              </router-link>
              <router-link :to="{ name: 'karriere-kontakt' }" class="dropdown-item">
                {{ $t('General.ansprechpartnerinnen') }}</router-link>
            </div>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'service' }" class="nav-link">{{ $t('General.service') }}</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-newsroom" data-bs-toggle="dropdown">
              {{ $t('General.newsroom') }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown-newsroom">
              <router-link :to="{ name: 'news' }" class="dropdown-item">{{ $t('General.news') }}</router-link>
              <!--<router-link :to="{ name: 'presskit' }" class="dropdown-item">{{$t('General.presskit')}}</router-link>-->
              <router-link :to="{ name: 'kontakt' }" class="dropdown-item">{{ $t('General.kontakt') }}</router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();

    const currentRoute = ref(router.currentRoute);

    return {
      currentRoute,
    };
  },
});
</script>
